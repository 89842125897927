/** @jsxImportSource @emotion/react */

import { CSSObject } from "@emotion/react";
import { Typography } from "@mui/material";
import { CSSProperties } from "react";

export const breakpoints = {
	// xs: 0,
	// sm: 600,
	// md: 900,
	// lg: 1200,
	// xl: 1536
	xxs: 300,
	xs: 600,
	sm: 900,
	md: 1200,
	lg: 1536,
	xl: 1850,
};

type ResponsiveSize = "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

export const ress = (
	// size: number | ResponsiveSize | string,
	size:
		| "xxs"
		| "xs"
		| "sm"
		| "md"
		| "lg"
		| "xl"
		| "xxl"
		// more than
		| "xxs+"
		| "xs+"
		| "sm+"
		| "md+"
		| "lg+"
		| "xl+"
		| "xxl+",
	style: CSSObject
) => {
	const isDescendant = !String(size).includes("+");
	const uSize = String(size) // unsigned size
		.replace("+", "")
		.replace("-", "");

	const object = {};
	const width = typeof uSize === `number` ? uSize : breakpoints[uSize];
	let selector: string;
	if (isDescendant) {
		selector = `@media only screen and (max-width: ${width - 0.05}px)`;
	} else {
		selector = `@media only screen and (min-width: ${width}px)`;
	}
	object[selector] = style;
	return object;
};

export const MText = (props: {
	children?: any;
	html?: string;
	className?: string;
	gradiented?: boolean;
	opacity?: number;
	color?:
		| "initial"
		| "inherit"
		| "primary"
		| "secondary"
		| "textPrimary"
		| "textSecondary"
		| "error";
	textAlign?: "center" | "left" | "right" | "justify";
	variant:
		| "h1"
		| "h2"
		| "h3"
		| "h4"
		| "h5"
		| "h6"
		| "subtitle1"
		| "body1"
		| "body2"
		| "button"
		| "caption"
		| "overline";
}) => {
	const cssStyles: CSSProperties[] = [];

	return (
		<Typography
			className={
				(props.className || "") +
				(props.gradiented ? " gradiented" : "")
			}
			variant={props.variant}
			align={props.textAlign}
			color={props.color || "primary"}
			children={props.children}
			dangerouslySetInnerHTML={
				props.html ? { __html: props.html } : undefined
			}
			css={cssStyles as any}
		/>
	);
};
