import { DeepKeyof, language } from "../../../traductions";

const traduction = {
  it: {
    pages: {
      item1: "Home",
      item2: "Manifesto",
      item3: "Progetti",
      item4: "Life at Mabiloft",
      item5: "Blog",
      item6: "Careers",
      contacts: "Contatti",
    },
  },
  en: {
    pages: {
      item1: "Home",
      item2: "Manifesto",
      item3: "Projects",
      item4: "Life at Mabiloft",
      item5: "Blog",
      item6: "Careers",
      contacts: "Contacts",
    },
  },
};

export const l_menu = (
  trad: DeepKeyof<typeof traduction[keyof typeof traduction]>
) => {
  const tokens = trad.split(".");
  let resource = traduction && (traduction as any)[language.code];
  if (!resource) {
    return tokens[tokens.length - 1];
  }

  for (let c = 0; c < tokens.length; c++) {
    resource = resource[tokens[c]];
    if (!resource) {
      // resource not found
      return trad;
    }
  }

  return resource;
};
