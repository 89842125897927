import { Box } from "@mui/material";
import React from "react";
import { animated, useSpring } from "react-spring";
import LanguageButton from "../../Topbar/components/LanguageButton";
import ContactsList from "./ContactsList";
import { MenuSize } from "../Menu";
import PagesList from "./PagesList";
import { useModalContext } from "../contexts/ModalContext";

const AnimatedBox = animated(Box);

const MenuContent = ({ scrollbarWidth }: { scrollbarWidth: number }) => {
  const { isOpen } = useModalContext();
  const [spring] = useSpring(() => {
    return {
      from: {
        opacity: isOpen ? 0 : 1,
      },
      to: {
        opacity: isOpen ? 1 : 0,
      },
      config: {
        duration: 500,
      },
    };
  }, [isOpen]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "auto",
      }}
    >
      <AnimatedBox
        sx={{
          position: "absolute",
          height: MenuSize.thumbSize.md,
          top: {
            xs: `${MenuSize.padding.xs}px`,
            md: `${MenuSize.padding.md}px`,
          },
          display: "flex",
          alignItems: "center",
          right: {
            md: `calc(${MenuSize.padding.md}px + ${scrollbarWidth}px + ${MenuSize.thumbSize.md}px + 40px)`,
            xs: `calc(${MenuSize.padding.xs}px + ${scrollbarWidth}px + ${MenuSize.thumbSize.xs}px + 40px)`,
          },
        }}
        style={{
          ...spring,
        }}
      >
        <LanguageButton whitened />
      </AnimatedBox>

      <Box
        sx={{
          width: "90%",
          maxWidth: "1800px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          boxSizing: "border-box",
          height: "100%",
          paddingTop: "10vh",
        }}
      >
        <PagesList />
        <ContactsList />
      </Box>
    </Box>
  );
};

export default MenuContent;
