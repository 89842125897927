module.exports = {
  en: {
    path: "en",
    locale: "English",
    default: true,
  },
  it: {
    path: "it",
    locale: "Italiano",
  },
};
