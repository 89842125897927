export const language = {
	code: "en",
  };
  
  export const L = (trad: DeepKeyof<typeof traduction[keyof typeof traduction]>) => {
	const tokens = trad.split(".");
	let resource = traduction && (traduction as any)[language.code];
  
	for (let c = 0; c < tokens.length; c++) {
	  resource = resource[tokens[c]];
	  if (!resource) {
		// resource not found
		return trad;
  
	  }
	}
  
	  return resource;
  };
  
  const traduction = {
	  en: {
		  home: {
			  claim: '<b>UX-Driven</b><br/>Software House',
			  description: 'We think before writing every line of code',
			  softwareQuality: 'Software quality',
			  choicesExplanation:
				  'Our choices are always driven by <b>User Experience</b>, in <b>designing</b> as well as in <b>coding</b>.',
			  back: 'Back',
			  teamworkTitle: "It's all about teamwork",
			  teamworkDescription:
				  "What you breathe between our desks it's a relashionship that goes beyond the work commitment, we believe in a good team, that let's us grow together every day.",
			  noCompromisesTitle: "No compromise",
			  noCompromisesDescription:
				  "We design and develop digital products without taking anything for granted. We are specialized in mobile app and platforms development: we follow a well defined workflow, that enables us to find the best solutions for our clients",
			  noCompromisesCTA: 'discover our manifesto',
			  chatWithTheTeam: "Chat with the team"
		  },
	  process: {
		research: {
		  title: "Research",
		  description:
			"We develop the project and decide how to deal with it, what are the strengths and possible critical issues. We direct the project in the best direction so as to maximize its income.",
		},
		design: {
		  title: "Design",
		  description:
			"We study and design, even visually, the entire logical flow by foreseeing every user action and directing it to perform the best action to make the software truly effective.",
		},
		prototype: {
		  title: "Prototyping",
		  description:
			"We create functional prototypes for the development of the software, in order to experience how each user interacts with the product. This allows you to better define the project before the development phase.",
		},
		develop: {
		  title: "Development",
		  description:
			"We carefully choose the best technologies that best adapt to the type of project, in order to optimize the development phase to obtain excellent quality software in every respect.",
		},
		test: {
		  title: "Testing",
		  description: 
			"We involve customer and testers during the creation phase, making the production a continuous exchange of feedback to push the product to the highest quality.",
		},
		release: {
		  title: "Release",
		  description:
			"Once the production phase has been completed, the software is released. Each software undergoes constant maintenance and updates in order to maintain a high quality level.",
		},
	  },
		  portfolio: {
			  title: "We <b>only</b> work on what we <b>believe in</b>",
			  subtitle:
			  "Carefully choosing projects and ideas to work on allows us to dedicate them more time and resources, thus obtaining products that really makes the difference",
			  ourJobs: "Our most important jobs",
			  believeInUs: "Who believes in us",
		  },
		  manifesto: {
			  title: "We don't close tasks, we <b>build</b> products",
			  noDiscount: "Without giving anything for granted",
			  noDiscountSubtitle:
				  "We work in digital product development, carefully studying every detail. Our aim is to create software by bringing outcome to the maximum, from backend to interfaces, which is why our projects always start with a careful analysis.",
			  weAreDevelopers: "We are <b>UX</b> Developers",
			  weAreDevelopersSubtitle:
				  "We feel the weight of user experience responsibility also in development: results of digital products depends a lot on the code and on how it is addressed. Caring for our code is fundamental.",
			  designTitle: "Design it's the <b>soul</b> of our job",
			  designDescription:
				  "We design digital products studying flows, user journey and wireframes as a solid foundation of the interface. We help our customers visualize their needs and insert them consistently in the project, applying the principals of design thinking.",
			  testTitle: 'We love to <b>prove</b> ourselves',
			  testDescription:
				  "We accompany our products in the testing phase, making production a continuous exchange of feedback in order to always bring the quality of the software to the maximum",
			  researchTitle: "We feed on <b>research</b>",
			  researchDescription:
				  "We use the latest technologies to improve performance and make cutting-edge software. We are committed to stay in line with the times and dedicating part of our week to research and development.",
			  projectsTitle: 
				  "Our projects are not limited to the final product, they are mostly what happens in the meantime.",
			  projectsDescription: 
				  "We follow our customers in all the phases of work, \nfrom the initial brief to the develoment and release.\nWe create a lasting bond and mutual trust",
			  proattivitaTitle: 'Proactivity',
			  proattivitaDescription:
				  "We care about our customers' projects as if they were our own, we are the first one to seek for improvement and solutions suited to the objectives set.",
			  passioneTitle: 'A full time passion',
			  passioneDescription:
				  "Love for our work pushes us to experiment and always find the best solution for our customers.",
			  standUpTitle: 'Daily stand-up',
			  standUpDescription:
				  "Communication within our team is essential for us. We update every morning on the development of projects to always be aligned.",
			  ryanTitle: 'We always save Private Ryan',
			  ryanDescription:
				  "Nothing and no one is left behind in our work, our main concern is to support the customer in his growth.",
			  controlloTitle: 'Everything under control',
			  controlloDescription:
				  "At Mabiloft projects are always tackled in a team of several people. More eyes, more control, creating a collaboration essential for the product realization.",
			  revisioniTitle: 'Periodic revision',
			  revisioniDescription:
				  "Involving the customer in the project development is essential to welcome feedbacks and to anticipate changes.",
			  tecnologiesTitle: "Tecnologies we fell in <b>love</b> with",
			  figmaDescription:
				  "Figma as designing tool let's us share in real time important information to all the team. Over the years has become our wingman for design",
			  flutterDescription:
				  "Unifying our apps codebase with Flutter enables us to oprimize projects maintainment without having to sacrifice performance, thus allowing us to concentrate on logic and interactions.",
			  reactDescription:
				  "We master React and React-Native, thanks to which we are able to give it all both on web and on mobile.",
			  backendRootsTitle: 'Roots in <b>Backend</b>',
			  backendRootsSubtitle:
				  "Solid products need solid bases. That's why we also take care of realizing and correctly integrate our customers backend",
			  backendRootsDescription:
				  "Our backend is developed in <b>Node.js</b> with <b>Typescript</b>. We realize containerized and scalable microservices with <b>Docker</b> and released with <b>GCloud</b> and <b>AWS</b>.",
		  },
		  blogSection: {
			  title: "A full time <b>passion</b>",
			  subtitle: "We love what we do, that’s why we can’t help but talk about it"
		  },
		  routes: {
			  about: 'About',
			  portfolio: 'Portfolio',
			  manifesto: 'Manifesto',
			  stories: 'Stories',
		  },
		  footer: {
			  nextWorkTitle: 'We only work on what we believe in',
			  nextWorkCTA: 'Discover our works',
			  newsletter: "newsletter",
			  thankYouSubscribe: "Thank you for your subscription",
			  newsletterChangeEmail: "Would you like to subscribe with another email?",
			  hearSoonCheckMail: "Talk to you soon, meanwhile check your mail",
			  newsletterCaption: "Subscribe to our newsletter",
			  newsletterHeader: "No spam! Occasionally we will share with you some of our blog articles, playlist, photos and office life episodes.",
			  newsletterYourMail: "Your email",
			  enterInTeam: "Discover how you can join the team",
				newsletterPrivacy:
			  "By entering your address, you confirm that you have read the <a href='/privacy' target='_blank' rel='noreferrer noopener'>privacy policy</a> and give us permission to update you via email for the marketing purposes described in the information.",
	  
		  },
		  contacts: {
			  getInTouch: 'Get in touch',
			  where: 'Where we are',
			  businessName: 'Mabiloft SRL',
			  whereLocation: 'Padova HQ, Via Luigi Cadorna 129, Albignasego 35020',
				emailPlaceholder: 'Insert your email',
				emailLabel: 'Your email',
				messagePlaceholder: 'Insert here your message',
				messageLabel: 'Your message',
				sendMessage: 'Send message',
				title: "Wanna collab or <b>do you want to know us better</b>?",
				description: "Otherwise we can play football table or maybe you can challenge us in our tennis table at our office. We also have a slush machine to make a proper granita. Well, contact us for everything you want, but not spam please ;)",
				fillForm: 'Fill the form, we will answer you as soon as possible',
				whereToFindUs: 'Where to find us',
				howToContactUs: 'How to contact us',
				followUs: 'Follow us',
				failedSending: 'Failed sending',
				failedSendingDescription: 'There seems to be some error in sending the email, we advise you to try again.',
				cancel: 'Cancel',
			},
      notFound:{
        title: "Oh no, this page doesn't exist",
		description:
			"We searched high and low, but we couldn't find it- Since you're here, why don't you play for a while with us?",
		watchCreations: "LOOK AT THE OTHER CREATIONS",
		backHomeIntro: "Otherwise, if you have no time to lose, ",
		backHome: "go back to the homepage",
		tryAgain: 'Try again',
		shapes: {
			square: "Square",
			circle: "Circle",
			rhombus: "Rhombus",
			clove: "Clove",
			pill: "Pill",
		},
		color: "Color",
		sendYourCreation: "Send your creation",
		thanksForShare: "Thanks! We received your creation",
		otherCreations: "The other creations that we received",
		yourName: "Your name",
		insertYourName: "Insert your name",
		yourCreationName: "Your creation's name",
		insertYourCreationName: "Give a name to your piece of art",
		wantAddADescription: "Would you like to add a description?",
		weReserveDescription:
			"*We reserve the right not to share on our website explicit and/or offensive pictures. Send whatever you created, though, at the very least we will have a laugh in our office.",
		theresNothingHere: "There is nothing here, yet!",
		sendFirstCreation: "Why don't you share the first creation?",
		somethingWentWrong: "Something went wrong",
		itCanHappen: "It happens! We'll quickly solve",
      },
		  partnersLabel: 'Who believes in us',
		  thankYouSubscribe: 'Thanks for signing up',
		  newsletterChangeEmail: 'Sign up with another email',
		  headerNewsletter:
			  'Would you like to know the latest news in the digital world?',
		  hearSoonCheckMail:
			  "We'll be in touch soon, in the meantime check your email",
		  descriptionNewsletter:
			  'Once a week, we will share information, opinions, curiosities and advice on the latest news from the digital world with you. If you want to know more, write your name and e-mail address below. We are waiting for you!',
		  subscribe: 'subscribe',
		  yourEmail: 'Your email',
		  errorAlreadySubscribed: 'You are already subscribed to our newsletter!',
		  generalErrorNewsletter:
			  'There was an error while registering, please try again later ...',
		  name: 'Name',
		  articleBy: 'by',
		  discoverMore: 'Show more',
		  articles: 'Articles',
		  moreArticles: 'More blog posts',
		  digitalization: 'Digitalization',
		  app: 'Mobile Apps',
		  web: 'Web Platforms',
		  design: 'UX & UI Design',
		  about: 'About',
		  blog: 'Blog',
		  works: 'Portfolio',
		  contact: 'Contacts',
		  howToContactUs: 'Contacts',
		  whereToFindUs: 'Where',
		  followUs: 'Follow us',
		  mabiloftLocation:
			  'Mabiloft\nVia Luigi Cadorna, 2\nAlbignasego\n35020 Padua, Italy',
		  allRightsReserved: 'All rights reserved',
		  ctaContactHeader: 'Have an idea?',
		  ctaContact: 'Hire us',
		  ctaContactDescription:
			  "We are always looking for new challenges.\nSend us a message, we can't wait to talk about it together!",
		  messageSent: 'Message sent',
		  retry: 'Retry',
		  sending: 'Sending..',
		  sendError: 'There was an error sending the message.',
		  invalidEmail: 'Please enter a valid email address',
		  invalidMessage: 'Please write more than 20 characters',
		  weAreHiring: 'We are Hiring!',
		  cookiesTitle: 'We use cookies 🍪',
		  cookiesDescription:
			  'By continuing to browse the site, you accept the use of technical and third-party cookies.',
		  cookiesMore: 'info',
		  cookiesClose: 'close',
		  pageNotFound: "We can't find this page",
		  backToHome: 'Back to home',
		  notFoundDescription:
			  'The content may have been deleted, or the link you clicked does not exist.',
		  months: [
			  'January',
			  'February',
			  'March',
			  'April',
			  'May',
			  'June',
			  'July',
			  'August',
			  'September',
			  'October',
			  'November',
			  'December',
		  ],
		  notAvailableInThisLanguage: 'Not available in english',
		  goToOtherLanguage: 'Go to italian version',
		  ourBlog: 'Our Blog',
		  fromOurBlog: 'From our Blog',
		  //? newsletter
		  newsletter: 'Newsletter',
		  newsletterCaption: "Ah, you've opened the newsletter link!",
		  newsletterTitle: "Now that you're here you might as well sign up.",
		  newsletterDescription: `Mabiloft's newsletter is informative, entertaining, sent at irregular intervals. I mean, we're not going to bother you unless it's necessary. We will share articles from our blog, playlists, photos and anecdotes about office life with you. From time to time we will send you invitations to events we'll attend, so that we can meet and chat together.
	
		  <h3>What’s the catch?</h3> 
		  Our newsletter is only in Italian, at least for now.
		  Seeing it from another perspective, it can help you learn Italian and know how to order a plate of pasta during your next trip to Italy! Or you could always just look at the photos. 
  
		  You just have to leave us your email 👇🏻`,
		  newsletterHint:
			  '*no spam, we promise, and above all your data will remain between us',
		  newsletterPrivacy:
			  "By entering your address, you confirm that you have read the <a href='/privacy' target='_blank' rel='noreferrer noopener'>privacy policy</a> and give us permission to update you via email for the marketing purposes described in the information.",
	  },
	  it: {
		  home: {
			  claim: '<b>UX-Driven</b><br/>Software House',
			  description:
				  'Prima di scrivere qualsiasi riga di codice ci mettiamo la testa',
			  softwareQuality: 'Qualità del software',
			  choicesExplanation:
				  'Le nostre scelte vengono sempre guidate dalla <b>User</b> <b>Experience</b>, nel <b>design</b> come nel <b>codice</b>.',
			  back: 'Indietro',
			  teamworkTitle: 'Il nostro è un lavoro di squadra',
			  teamworkDescription:
				  'Ciò che si respira tra le nostre scrivanie è un legame che va oltre all’impegno lavorativo, crediamo nell’importanza di un buon team, che ci faccia crescere ogni giorno insieme.',
			  noCompromisesTitle: 'Non scendiamo a compromessi',
			  noCompromisesDescription:
				  'Sviluppiamo e progettiamo prodotti digitali non dando mai nulla per scontato. Siamo specializzati nello sviluppo di app mobile e piattaforme: lavoriamo seguendo un flusso ben definito, che ci permette di trovare le migliori soluzioni per i nostri clienti.',
			  noCompromisesCTA: 'scopri il nostro manifesto',
			  chatWithTheTeam: "chatta con il team"
  
		  },
	  process: {
		research: {
		  title: "Analisi",
		  description:
			"Elaboriamo il progetto e decidiamo come affrontarlo, quali sono i punti di forza e le possibili criticità. Orientiamo il progetto nella direzione migliore in modo da massimizzare la sua rendita.",
		},
		design: {
		  title: "Design",
		  description:
			"Studiamo e progettiamo, anche visivamente, l’intero flusso logico prevedendo ogni azione dell’utente. Lo indirizziamo a compiere l’azione migliore, per rendere così il software veramente efficace.",
		},
		prototype: {
		  title: "Prototipazione",
		  description:
			"Creiamo prototipi funzionali allo sviluppo del software, così da riscontare con mano come ogni utente interagisce col prodotto.",
		},
		develop: {
		  title: "Sviluppo",
		  description:
			"Scegliamo con cura le migliori tecnologie che meglio si adattano alla tipologia di progetto, così da ottimizzare la fase di sviluppo per ottenere un software di ottima qualità sotto ogni aspetto.",
		},
		test: {
		  title: "Testing",
		  description:
			"Coinvolgiamo il cliente ed i tester a provare il software durante la fase di creazione, rendendo la produzione un continuo scambio di feedback per spingere il prodotto alla massima qualità.",
		},
		release: {
		  title: "Rilascio",
		  description:
			"Una volta portato a compimento la fase di produzione il software viene rilasciato sulle piattaforme prestabilite. Ogni software viene sottoposto a costanti manutenzioni e aggiornamenti per poter mantere alto il livello qualitativo.",
		},
	  },
		  portfolio: {
			  title: 'Lavoriamo <b>solo</b> su ciò in cui <b>crediamo</b>',
			  subtitle:
				  'Scegliere con cura idee e progetti su cui lavorare ci permette di dedicarvi più tempo e risorse, ottenendo così prodotti che facciano davvero la differenza',
			  ourJobs: 'I nostri lavori più importanti',
			  believeInUs: 'Credono in noi',
		  },
		  manifesto: {
			  title: 'Non chiudiamo task, <b>costruiamo</b> prodotti',
			  noDiscount: 'Senza dare mai nulla per scontato',
			  noDiscountSubtitle:
				  'Lavoriamo nelle aree dello sviluppo di prodotti digitali, app e piattaforme, studiando accuratamente ogni dettaglio. Il nostro fine è quello di creare software portando la realizzazione al massimo, dal backend alle interfacce, per questo i nostri progetti partono sempre da un’attenta analisi.',
			  weAreDevelopers: 'Siamo <b>UX</b> Developers',
			  weAreDevelopersSubtitle:
				  'Sentiamo anche nello sviluppo la responsabilità della user experience: nei prodotti digitali il risultato dipende molto dal codice e da come viene affrontato. La cura per il nostro codice è fondamentale.',
			  designTitle: "Il design è l'<b>anima</b> del nostro lavoro",
			  designDescription:
				  'Progettiamo prodotti digitali studiando flussi, user journey e wireframe come solido fondamento dell’interfaccia. Aiutiamo i nostri clienti a visualizzare le loro esigenze e a inserirle in modo coerente nel progetto, applicando i principi del design thinking.',
			  testTitle: 'Amiamo metterci alla <b>prova</b>',
			  testDescription:
				  'Accompagniamo i nostri prodotti anche nella fase di test, rendendo la produzione un continuo scambio di feedback in modo da portare la qualità del software sempre al massimo',
			  researchTitle: 'Ci alimentiamo di <b>ricerca</b>',
			  researchDescription:
				  'Studiamo e valutiamo le tecnologie più recenti per capire quali siano le migliori e le più adatte ai nostri progetti. Ci impegniamo ad essere al passo con i tempi e a dedicare parte della nostra settimana a ricerca e sviluppo.',
			  projectsTitle: `I nostri progetti non si limitano al prodotto finale, sono soprattutto ciò che succede nel mentre.`,
			  projectsDescription: `Seguiamo i nostri clienti in tutte le fasi di lavoro,\ndal brief iniziale allo sviluppo e rilascio.\nCreiamo un legame duraturo e di fiducia reciproca.`,
			  proattivitaTitle: 'Proattività',
			  proattivitaDescription:
				  'Teniamo ai progetti dei nostri clienti come se fossero nostri, siamo i primi a cercare miglioramenti e soluzioni adatte agli obiettivi prefissati.',
			  passioneTitle: 'Una passione a tempo pieno',
			  passioneDescription:
				  'L’amore per quello che facciamo ci spinge a sperimentare e trovare sempre la soluzione migliore per i nostri clienti',
			  standUpTitle: 'Stand-up quotidiane',
			  standUpDescription:
				  'La comunicazione all’interno del nostro team per noi è fondamentale. Ci aggiorniamo ogni mattina sugli sviluppi dei progetti che stiamo seguendo per essere sempre allineati.',
			  ryanTitle: 'Salviamo sempre il soldato Ryan',
			  ryanDescription:
				  'Niente e nessuno viene lasciato indietro nel nostro lavoro, la nostra principale preoccupazione è supportare il cliente nella sua crescita.',
			  controlloTitle: 'Tutto sotto controllo',
			  controlloDescription:
				  'A Mabiloft i progetti vengono sempre affrontati in team di più persone. Più occhi, più controllo, dando vita a una collaborazione essenziale per la realizzazione del prodotto.',
			  revisioniTitle: 'Revisioni periodiche',
			  revisioniDescription:
				  'Coinvolgere il cliente nello sviluppo del progetto è fondamentale per accogliere al meglio feedback e anticipare modifiche.',
			  tecnologiesTitle: 'Le tecnologie di cui ci siamo <b>innamorati</b>',
			  figmaDescription:
				  'Figma come tool di progettazione ci permette di condividere in tempo reale le informazioni importanti a tutto il team. Negli anni, è diventato la nostra spalla per il design.',
			  flutterDescription:
				  'Unificare le codebase delle nostre app con Flutter ci permette di  ottimizzare il mantenimento dei progetti senza doverne sacrificare le performace, permettendoci così di concentrarci sulla logica e sulle interazioni.',
			  reactDescription:
				  'Padroneggiamo React e React-Native, grazie ai quali riusciamo a dare il massimo sia sul web che sul mobile.',
			  backendRootsTitle: 'Radici nel <b>Backend</b>',
			  backendRootsSubtitle:
				  'Prodotti solidi necessitano di basi solide. Per questo ci occupiamo anche di realizzare e di integrare correttamente i backend dei nostri software',
			  backendRootsDescription:
				  'Il nostro backend viene sviluppato in <b>Node.js</b> con <b>Typescript</b>. Realizziamo microservizi containerizzati e scalabili con <b>Docker</b> e lanciati con <b>GCloud</b> e <b>AWS</b>.',
		  },
		  blogSection: {
			  title: "Una <b>passione</b> a tempo pieno",
			  subtitle: "Amiamo quello che facciamo, per questo non possiamo fare a meno di parlarne"
		  },
		  footer: {
			  nextWorkTitle: 'Lavoriamo solo su ciò in cui crediamo',
			  nextWorkCTA: 'Scopri i nostri lavori',
			  newsletter: "newsletter",
			  thankYouSubscribe: "Grazie per l'iscrizione",
			  newsletterChangeEmail: "Vuoi iscriverti con un'altra email?",
			  hearSoonCheckMail: "Ci sentiremo presto, intanto controlla la mail",
			  newsletterCaption: "Iscriviti alla newsletter",
			  newsletterHeader: "Niente Spam! Di tanto in tanto condivideremo con te articoli del nostro blog, playlist, foto e aneddoti sulla vita in ufficio.",
			  newsletterYourMail: "La tua email",
			  newsletterPrivacy: "Inserendo il tuo indirizzo confermi di aver letto la <a href='/privacy' target='_blank' rel='noreferrer noopener'>privacy policy</a> e ci dai il permesso di aggiornarti via email per le finalità di marketing descritte nell’informativa.",
			  enterInTeam: "Scopri come entrare nel team"
  
		  },
		  routes: {
			  about: 'About',
			  portfolio: 'Portfolio',
			  manifesto: 'Manifesto',
			  stories: 'Stories',
		  },
		  contacts: {
			  getInTouch: 'Get in touch',
			  where: 'Where we are',
			  businessName: 'Mabiloft SRL',
			  whereLocation: 'Padova HQ, Via Luigi Cadorna 129, Albignasego 35020',
				emailPlaceholder: 'Inserisci la tua email',
				emailLabel: 'La tua email',
				messagePlaceholder: 'Scrivi qui il tuo messaggio',
				messageLabel: 'Il tuo messaggio',
				sendMessage: 'Invia messaggio',
				title: "Vuoi collaborare o <b>conoscerci meglio</b>?",
				description: "In alternativa possiamo giocare a calcio balilla o fare una partita di ping pong in ufficio, abbiamo anche una macchina per fare le granite. Insomma, contattaci per qualsiasi cosa, no televendite però.",
				fillForm: 'Compila il form, ti risponderemo al più presto!',
				whereToFindUs: 'Dove trovarci',
				howToContactUs: 'Come contattarci',
				followUs: 'Seguici',
				failedSending: 'Invio non riuscito',
				failedSendingDescription: 'Sembrerebbe esserci qualche errore nell’invio, ti consigliamo di riprovare.',
				cancel: 'Annulla',
		  },
      notFound:{
        title: "Oh no, questa pagina non esiste",
		description:
			"Abbiamo cercato in lungo e in largo, ma non l’abbiamo trovata. Visto che sei qui, che ne dici di giocare un po’ con noi?",
		watchCreations: "GUARDA LE ALTRE CREAZIONI",
		backHomeIntro: "Altrimenti, se non hai tempo da perdere, ",
		backHome: "torna alla home",
		tryAgain: 'Riprova',
		shapes: {
			square: "Quadrato",
			circle: "Cerchio",
			rhombus: "Rombo",
			clove: "Spicchio",
			pill: "Pillola",
		},
		color: "Colore",
		sendYourCreation: "Invia la tua creazione",
		thanksForShare: "Grazie! Abbiamo ricevuto la tua creazione",
		otherCreations: "Le altre creazioni che abbiamo ricevuto",
		yourName: "Il tuo nome",
		insertYourName: "Inserisci il tuo nome",
		yourCreationName: "Il nome della tua creazione",
		insertYourCreationName: "Dai un nome alla tua opera d'arte",
		wantAddADescription: "Vuoi aggiungere una descrizione?",
		weReserveDescription:
			"*Ci riserviamo di non condividere sul nostro sito immagini esplicite e/o offensive. In ogni caso manda quello che hai fatto, al massimo ci faremo una risata dal nostro ufficio.",
		theresNothingHere: "Non c’è ancora nulla qui!",
		sendFirstCreation: "Che ne dici di mandare tu la prima creazione?",
		somethingWentWrong: "Qualcosa è andato storto",
		itCanHappen: "Può succedere! Risolviamo presto",
      },
		  //!---old
		  partnersLabel: 'Credono in noi',
		  articleBy: 'di',
		  discoverMore: 'Scopri di più',
		  articles: 'Articoli',
		  moreArticles: 'Altri articoli',
		  digitalization: 'Digitalizzazione',
		  app: 'Applicazioni',
		  web: 'Piattaforme Web',
		  design: 'Progettazione UX',
		  about: 'Chi siamo',
		  blog: 'Blog',
		  works: 'Portfolio',
		  contact: 'Contatti',
		  howToContactUs: 'Come contattarci',
		  whereToFindUs: 'Dove trovarci',
		  followUs: 'Seguici',
		  mabiloftLocation:
			  'Mabiloft\nVia Luigi Cadorna, 2\nAlbignasego\n35020 Padova, Italia',
		  allRightsReserved: 'Tutti i diritti sono riservati',
		  ctaContactHeader: "Hai un'idea?",
		  ctaContact: 'Scrivici',
		  ctaContactDescription:
			  "Siamo sempre alla ricerca di nuove sfide.\nMandaci un messaggio, non vediamo l'ora di parlarne insieme!",
		  messageSent: 'Messaggio inviato',
		  retry: 'Riprova',
		  sending: 'Invio...',
		  sendError: "C'è stato un errore durante l'invio del messaggio.",
		  invalidEmail: 'Per favore inserisci un indirizzo valido',
		  invalidMessage: 'Per favore scrivi più di 20 caratteri',
		  weAreHiring: 'We are Hiring!',
		  cookiesTitle: 'Usiamo i cookies 🍪',
		  cookiesDescription:
			  "Proseguendo la navigazione nel sito accetti l'utilizzo di cookie tecnici e di terze parti.",
		  cookiesMore: 'informazioni',
		  cookiesClose: 'chiudi',
		  pageNotFound: 'Non riusciamo a trovare questa pagina',
		  backToHome: 'Torna alla home',
		  notFoundDescription:
			  'Il contenuto potrebbe essere stato eliminato, o il link che hai cliccato essere inesistente.',
		  months: [
			  'Gennaio',
			  'Febbraio',
			  'Marzo',
			  'Aprile',
			  'Maggio',
			  'Giugno',
			  'Luglio',
			  'Agosto',
			  'Settembre',
			  'Ottobre',
			  'Novembre',
			  'Dicembre',
		  ],
		  notAvailableInThisLanguage: 'Non disponibile in italiano',
		  goToOtherLanguage: 'Vai alla versione inglese',
		  ourBlog: 'Il nostro Blog',
		  fromOurBlog: 'Dal nostro Blog',
		  //? newsletter
		  thankYouSubscribe: "Grazie per l'iscrizione",
		  newsletterChangeEmail: "Vuoi iscriverti con un'altra email?",
		  headerNewsletter: 'Vorresti conoscere le ultime novità del mondo digitale?',
		  hearSoonCheckMail: 'Ci sentiremo presto, intanto controlla la mail',
		  // descriptionNewsletter: "Condivideremo con te informazioni, opinioni, curiosità e consigli sulle ultime novità del mondo digitale. Se vuoi saperne di più, inserisci il tuo indirizzo email qui sotto. Ti aspettiamo!",
		  descriptionNewsletter:
			  'Condivideremo con te gli articoli del nostro blog, playlist, foto e aneddoti sulla vita in ufficio. Di tanto in tanto ti faremo avere inviti ad eventi a cui parteciperemo, in modo da poterci incontrare e fare due chiacchiere insieme.',
		  subscribe: 'iscriviti',
		  yourEmail: 'La tua email',
		  errorAlreadySubscribed: 'Sei già iscritto alla nostra newsletter!',
		  generalErrorNewsletter:
			  "C'è stato un errore durante l'iscrizione, riprova più tardi...",
		  name: 'Nome',
		  //? newsletter page
		  newsletter: 'Newsletter',
		  newsletterCaption: 'Ah, hai aperto il link per la newsletter!',
		  newsletterTitle: 'Ora che sei qui tanto vale iscriversi.',
		  newsletterDescription: `La newsletter di Mabiloft è informativa, spesso divertente, inviata ad intervalli irregolari. Insomma, non abbiamo intenzione di disturbarti a meno che non sia necessario. Condivideremo con te gli articoli del nostro blog, playlist, foto e aneddoti sulla vita in ufficio. Di tanto in tanto ti faremo avere inviti ad eventi a cui parteciperemo, in modo da poterci incontrare e fare due chiacchiere insieme.
  
		  Devi solo lasciarci la tua mail 👇🏻`,
		  newsletterHint:
			  '*niente spam, promesso, e soprattutto i tuoi dati rimaranno tra di noi',
		  newsletterPrivacy:
			  "Inserendo il tuo indirizzo confermi di aver letto la <a href='/privacy' target='_blank' rel='noreferrer noopener'>privacy policy</a> e ci dai il permesso di aggiornarti via email per le finalità di marketing descritte nell’informativa.",
	  },
  };
  
  //!--utils
  
  type Join<K, P> = K extends string | number  ? P extends string | number ? `${K}${"" extends P ? "" : "."}${P}` : never  : never;
  
  type Paths<T> = T extends object
	? {
		[K in keyof T]-?: K extends string | number
		  ? `${K}` | Join<K, Paths<T[K]>>
		  : never;
	  }[keyof T]
	: "";
  
  export type DeepKeyof<T extends object> = Array<Paths<T>>[number];