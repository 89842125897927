/** @jsxImportSource @emotion/react */
import { easings } from "@react-spring/web";
import { animated, useSpring } from "react-spring";

function MenuIcon(
  props: React.SVGProps<SVGSVGElement> & { size?: number; isMenuOpen: boolean }
) {
  const { size = 47, isMenuOpen, ...otherProps } = props;
  // const size = size || 47;

  const [spring] = useSpring(() => {
    return {
      value: props.isMenuOpen ? 0 : 1,
      config: {
        duration: 600,
        easing: easings.easeInOutQuart,
      },
    };
  }, [props.isMenuOpen]);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
      css={{
        transition: "fill 2s",
      }}
    >
      <rect
        width={47}
        height={47}
        rx={23.5}
        fill={props.isMenuOpen ? "#fff" : "#000"}
        className="menuContainerRect"
      />

      <animated.rect
        x={14}
        width={18}
        height={3}
        rx={1}
        fill={props.isMenuOpen ? "#EB683D" : "#fff"}
        style={{
          y: spring.value.to([0, 0.3, 1], [22, 13, 16]).to((value) => value),
          // fill: spring.value
          //   .to([0, 1], ["#EB683D", "#fff"])
          //   .to((value) => value),
        }}
      />

      <rect
        x={14}
        y={22}
        width={18}
        height={3}
        rx={1}
        fill={props.isMenuOpen ? "#EB683D" : "#fff"}
      />

      <animated.rect
        x={14}
        width={18}
        height={3}
        rx={1}
        fill={props.isMenuOpen ? "#EB683D" : "#fff"}
        style={{
          y: spring.value.to([0, 0.3, 1], [22, 31, 28]).to((value) => value),
          // fill: spring.value
          //   .to([0, 1], ["#EB683D", "#fff"])
          //   .to((value) => value),
        }}
      />
    </svg>
  );
}

export default MenuIcon;
