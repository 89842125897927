/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const transitionDelay = 500;

exports.onClientEntry = () => {
  const keyLocalization = "localization";

  const toCheckLang = localStorage.getItem(keyLocalization);

  const pathname = window.location.pathname;
  const langPath = pathname.includes("/it") ? "it" : "en";

  if (toCheckLang && langPath !== toCheckLang) {
    const newPath =
      toCheckLang === "it" ? `/it${pathname}` : pathname.replace("/it/", "/");
    window.location.replace(newPath);
  }
};

// exports.shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   window.history.scrollRestoration = "manual";
//   const currentPosition = getSavedScrollPosition(location);
//   window.setTimeout(() => {
//     window.scrollTo(...currentPosition);
//   }, transitionDelay);
//   return false;
// };

// exports.shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   if (location.action === "PUSH") {
//     window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
//   } else {
//     const savedPosition = getSavedScrollPosition(location);
//     window.setTimeout(
//       () => window.scrollTo(...(savedPosition || [0, 0])),
//       transitionDelay
//     );
//   }
//   return false;
// };
