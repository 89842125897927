/** @jsxImportSource @emotion/react */
import { Box, Button, Stack, Typography } from "@mui/material";

import { StaticImage } from "gatsby-plugin-image";
import { ReactNode } from "react";
import { animated, useSpring } from "react-spring";
import { useModalContext } from "../contexts/ModalContext";
import { l_menu } from "../locales";
import ButtonWithUnderline from "../../ButtonWithUnderline";

const AnimatedStack = animated(Stack);

const SocialButton = ({
  link,
  name,
  icon,
}: {
  link: string;
  name: string;
  icon: ReactNode;
}) => {
  return (
    <Button
      variant="contained"
      startIcon={icon}
      disableRipple
      sx={{
        backgroundColor: "#ffffff12",
        padding: { md: "8px 16px", xs: "2.5vh" },
        borderRadius: "32px",
        color: "#fff",
        textTransform: "none",
        "@media only screen and (max-width: 600px)": {
          height: "6vh",
          "span > div.gatsby-image-wrapper": {
            width: "3.5vh",
            height: "3.5vh",
          },
        },
        "> span": {
          marginRight: "16px",
          marginLeft: 0,
        },
        ":hover": {
          backgroundColor: "#ffffff24",
        },
      }}
      href={link}
      target="_blank"
    >
      <Typography
        sx={{
          fontSize: { md: "20px", xs: "2vh" },
          lineHeight: { md: "28px", xs: "2.2vh" },
        }}
      >
        {name}
      </Typography>
    </Button>
  );
};

const ContactsList = () => {
  const { isOpen, setMenuOpen } = useModalContext();
  const [spring] = useSpring(() => {
    return {
      from: {
        opacity: isOpen ? 0 : 1,
      },
      to: {
        opacity: isOpen ? 1 : 0,
      },
      delay: isOpen ? 500 : 0,
      config: {
        duration: 200,
      },
    };
  }, [isOpen]);

  const socials = [
    {
      name: "Instagram",
      link: "https://www.instagram.com/mabiloft/?hl=en",
      icon: (
        <StaticImage src="../images/InstagramLogo.svg" alt="Instagram logo" />
      ),
    },
    {
      name: "LinkedIn",
      link: "https://it.linkedin.com/company/mabiloft",
      icon: (
        <StaticImage src="../images/LinkedinLogo.svg" alt="LinkedIn logo" />
      ),
    },
  ];

  return (
    <AnimatedStack
      sx={{
        flexDirection: { md: "row", xs: "column" },
        justifyContent: "space-between",
        alignItems: { md: "flex-end", xs: "flex-start" },
        marginTop: { md: "0px", xs: "10vh" },
      }}
      style={{ ...spring }}
    >
      <Stack
        sx={{
          gap: "16px",
          flexDirection: "row",
          order: { md: 1, xs: 2 },
        }}
      >
        {socials.map((social) => {
          return (
            <SocialButton
              link={social.link}
              name={social.name}
              icon={social.icon}
              key={social.name}
            />
          );
        })}
      </Stack>

      <Box
        sx={{ order: { md: 2, xs: 1 }, paddingBottom: { md: 0, xs: "32px" } }}
      >
        <ButtonWithUnderline
          children={l_menu("pages.contacts")}
          link="/contacts"
          textStyle={{
            color: "white",
            fontWeight: 900,
            fontSize: "2.9vh",
          }}
          lineStyle={{ height: "3px", backgroundColor: "white" }}
          onClick={() => {
            setMenuOpen(!isOpen);
          }}
        />
      </Box>
    </AnimatedStack>
  );
};

export default ContactsList;
