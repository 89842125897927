import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import { createElement, CSSProperties, ReactNode, useRef } from "react";
import { animated, useSpring } from "react-spring";
import { LocalizedLink } from "../LocalizedLink";

const AnimatedBox = animated(Box);

type LinksProp = {
  link?: string;
  children: ReactNode;
  textStyle?: SxProps;
  lineStyle?: SxProps;
  style?: CSSProperties;
  defaultUnderlined?: boolean;
} & Omit<BoxProps, "children"> &
  ({ link: string } | ({ link?: never } & ButtonProps));

const ButtonWithUnderline: React.FC<LinksProp> = ({
  link,
  children,
  textStyle,
  lineStyle,
  style,
  sx,
  defaultUnderlined,
  ...props
}) => {
  const [spring, api] = useSpring(() => {
    return {
      value: defaultUnderlined ? 0 : -1,
      config: {
        duration: 300,
      },
    };
  });
  const theme = useTheme();

  const hasFinishedAnimating = useRef(false);

  //@ts-ignore
  return createElement(link ? LocalizedLink : Button, {
    ...(link
      ? { to: link }
      : {
          disableRipple: true,
          sx: {
            // paddingX: 0,
            background: "transparent !important",
            transition: theme.transitions.create("color"),
            "&.Mui-disabled": { color: "rgba(0, 0, 0, .36) !important" },
            ":active": {
              color: "rgba(255, 122, 0, 1) !important",
              "& .underline": { backgroundColor: "rgba(255, 122, 0, 1)" },
            },
            padding: 0,
            borderRadius: 0,

            ...sx,
          },
          ...props,
        }),
    style: {
      textDecoration: "none",
      color: "inherit",
      width: "fit-content",
      display: "block",
      overflow: "hidden",
      backgroundColor: "transparent",
    },
    children: (
      <>
        <Box
          onMouseEnter={() => {
            hasFinishedAnimating.current = false;
            api.set({
              value: defaultUnderlined ? 0 : -1,
            });
            api.start({
              value: defaultUnderlined ? 1 : 0,
              onResolve: () => {
                hasFinishedAnimating.current = true;
              },
            });
          }}
          onMouseLeave={() => {
            if (!hasFinishedAnimating.current) {
              api.start({
                value: defaultUnderlined ? 0 : -1,
              });
            } else {
              if (defaultUnderlined) {
                api.set({
                  value: -1,
                });
              }
              api.start({
                value: defaultUnderlined ? 0 : 1,
              });
            }
          }}
          sx={{
            backgroundColor: "transparent",
            width: "fit-content",
            ":hover": {
              backgroundColor: "transparent",
            },
            ...style,
            ...(link && { ...sx }),
          }}
          {...(link && { ...props })}
        >
          <Typography
            sx={{
              textTransform: "none",
              position: "relative",
              overflow: "hidden",
              ...textStyle,
            }}
            component="span"
          >
            {children}
          </Typography>
        </Box>
        <AnimatedBox
          className="underline"
          style={{
            transform: spring.value
              .to([-1, 0, 1], [-130, 0, 130])
              .to((value) => `translateX(${value}%)`),
          }}
          sx={{
            width: "100%",
            height: "2px",
            //@ts-ignore
            backgroundColor: props.disabled ? "rgba(0, 0, 0, .36)" : "black",
            transition: theme.transitions.create("background-color"),
            ...lineStyle,
          }}
        />
      </>
    ),
  });
  // <LocalizedLink
  // 	to={link}
  // 	style={{
  // 		textDecoration: "none",
  // 		color: "inherit",
  // 		width: "fit-content",
  // 		display: "block",
  // 		overflow: "hidden",
  // 	}}
  // >

  // </LocalizedLink>
};

export default ButtonWithUnderline;
