import { alpha, createTheme, adaptV4Theme } from "@mui/material";
import { ress } from "../components/shared/mtext";

declare module "@mui/material/styles/createPalette" {
	interface Palette {
		loading: Palette["primary"];
	}
	interface PaletteOptions {
		loading: PaletteOptions["primary"];
	}
}

const successColor = "#34C759";
const warningColor = "#FFCC00";
const errorColor = "#FF3B30";
const loadingColor = "#0A84FF";

const theme = createTheme(
	adaptV4Theme({
		palette: {
			primary: {
				main: "#1B203C",
				light: alpha("#1B203C", 0.74),
				contrastText: "#FDF0EB",
			},
			success: {
				main: successColor,
				contrastText: "#fff",
			},
			warning: {
				main: warningColor,
				contrastText: "#000",
			},
			error: {
				main: errorColor,
				contrastText: "#000",
			},
			loading: {
				main: loadingColor,
				contrastText: "#fff",
			},
			secondary: {
				main: "#E85322",
			},
		},
		typography: {
			allVariants: {
				fontFamily: ["Nunito Sans", "sans-serif"].join(", "),
				fontWeight: 600,
				lineHeight: "1.35",
				letterSpacing: 0.5,
			},
			h1: {
				fontSize: 200,
				fontWeight: 800,
				lineHeight: "1",
				letterSpacing: 0.25,
				...ress("lg", {
					fontSize: 144,
				}),
			},
			h2: {
				fontSize: 144,
				fontWeight: 700,
				lineHeight: "1",
				letterSpacing: -7.5,
				...ress("lg", {
					fontSize: 96,
					letterSpacing: -5,
				}),
				...ress("sm", {
					fontSize: 36,
					letterSpacing: 0.25,
					lineHeight: 1.35,
					fontWeight: 800,
				}),
			},
			h3: {
				fontSize: 96,
				fontWeight: 700,
				letterSpacing: -1.5,
				...ress("lg", {
					fontSize: 72,
				}),
				...ress("sm", {
					fontSize: 36,
					fontWeight: 800,
				}),
			},
			h4: {
				fontSize: 64,
				fontWeight: 800,
				lineHeight: "1",
				letterSpacing: -0.5,
				...ress("lg", {
					fontSize: 56,
					letterSpacing: 0,
				}),
				...ress("sm", {
					fontSize: 24,
					letterSpacing: 0.25,
				}),
			},
			h5: {
				fontSize: 48,
				fontWeight: 800,
				...ress("lg", {
					fontSize: 42,
				}),
			},
			h6: {
				fontSize: 36,
				letterSpacing: 0.25,
				...ress("lg", {
					fontSize: 32,
				}),
				...ress("sm", {
					fontSize: 16,
				}),
			},
			subtitle1: {
				fontSize: 24,
				fontWeight: 400,
				letterSpacing: 0.5,
				...ress("lg", {
					fontSize: 20,
				}),
				...ress("sm", {
					fontSize: 16,
				}),
			},
			subtitle2: {
				fontSize: 22,
				lineHeight: "1",
				letterSpacing: 0.15,
				...ress("lg", {
					fontSize: 18,
				}),
				...ress("sm", {
					fontSize: 14,
				}),
			},
			body1: {
				fontSize: 16,
				lineHeight: "1.375",
				letterSpacing: 0.5,
			},
			body2: {
				fontSize: 14,
				letterSpacing: 0.25,
			},
			button: {
				fontSize: 15,
				fontWeight: 600,
				lineHeight: "1",
				letterSpacing: 1.25,
			},
			caption: {
				fontSize: 12,
				lineHeight: "1.15",
				letterSpacing: 0.4,
			},
			overline: {
				fontSize: 10,
				lineHeight: "1.2",
				letterSpacing: 0.75,
			},
		},
		props: {
			MuiButton: {
				disableElevation: true,
			},
			MuiCard: {
				elevation: 0,
			},
		},
		overrides: {
			MuiButton: {
				root: {
					lineHeight: 2,
					padding: "8px 16px",
					borderRadius: 12,
					backgroundColor: alpha("#E85322", 0.06),
					color: "#E85322",
					"&:hover": {
						backgroundColor: alpha("#E85322", 0.12),
					},
				},
				text: {
					padding: "8px 16px",
				},
			},
		},
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 900,
				lg: 1200,
				xl: 1920,
			},
		},
	})
);

export const darkTheme = createTheme(theme, {
	palette: {
		mode: "dark",
		primary: {
			main: "#FFFFFF",
			light: alpha("#FFFFFF", 0.6),
			dark: "#FDF0EB",
			contrastText: "#E85322",
		},
	},
});

export default theme;
