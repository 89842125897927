import { Box, Stack, Typography } from "@mui/material";
import { animated, useSpring } from "react-spring";
import { useModalContext } from "../contexts/ModalContext";
import ButtonWithUnderline from "../../ButtonWithUnderline";

import { l_menu } from "../locales";
const AnimatedBox = animated(Box);
const AnimatedStack = animated(Stack);

const PagePoint = ({
  link,
  name,
  index,
}: {
  link: string;
  name: string;
  index: number;
}) => {
  const { isOpen, setMenuOpen } = useModalContext();

  const [appearanceSpring, api] = useSpring(
    () => ({
      from: { opacity: isOpen ? 0 : 1, y: isOpen ? 50 : 0 },
      to: { opacity: isOpen ? 1 : 1, y: 0 },
      delay: 500 + 100 * index,
    }),
    [isOpen]
  );

  return (
    <AnimatedBox
      style={{
        ...appearanceSpring,
      }}
      sx={{ width: "fit-content" }}
      onClick={() => setMenuOpen(!isOpen)}
    >
      <ButtonWithUnderline
        link={link}
        textStyle={{
          fontWeight: 900,
          fontSize: {
            xs: "4.705882353vh",
            md: "7.804878049vh",
          },
          color: "#fff",
        }}
        lineStyle={{
          height: "4px",
          backgroundColor: "white",
          marginTop: "-3px",
          marginBottom: "3px",
        }}
      >
        {name === "Life at Mabiloft" ? (
          <Typography
            sx={{
              fontWeight: "inherit",
              fontSize: "inherit",
              lineHeight: "inherit",
              fontFamily: "inherit",
            }}
          >
            <Typography
              component={"span"}
              sx={{
                fontWeight: 400,
                fontSize: "inherit",
                lineHeight: "inherit",
                fontFamily: "Metal",
              }}
            >
              Life
            </Typography>{" "}
            at Mabiloft
          </Typography>
        ) : (
          name
        )}
      </ButtonWithUnderline>
    </AnimatedBox>
  );
};

const PagesList = ({}: {}) => {
  const pages = [
    {
      name: l_menu("pages.item1"),
      link: "/",
    },
    {
      name: l_menu("pages.item2"),
      link: "/manifesto",
    },
    {
      name: l_menu("pages.item3"),
      link: "/works",
    },
    {
      name: l_menu("pages.item4"),
      link: "/life-at-mabiloft",
    },
    {
      name: l_menu("pages.item5"),
      link: "/blog",
    },
    {
      name: l_menu("pages.item6"),
      link: "/careers",
    },
  ];
  const { isOpen } = useModalContext();
  const [spring] = useSpring(() => {
    return {
      value: isOpen ? 0 : 1,
    };
  }, [isOpen]);

  return (
    <AnimatedStack
      sx={{
        justifyContent: "space-between",
      }}
      style={{
        y: spring.value.to([1, 0], [-20, 0]).to((value) => value),
        opacity: spring.value.to([1, 0], [0, 1]).to((value) => value),
      }}
    >
      {pages.map((page, index) => {
        return (
          <PagePoint
            key={page.link}
            link={page.link}
            name={page.name}
            index={index}
          />
        );
      })}
    </AnimatedStack>
  );
};

export default PagesList;
