import { createContext, useContext, useEffect, useState } from "react";

export function useModalContextValue({
  isOpen,
  setMenuOpen,
}: {
  isOpen: boolean;
  setMenuOpen: (isOpen: boolean) => void;
}) {
  const [isActuallyOpen, setIsActuallyOpen] = useState(isOpen);

  useEffect(() => {
    if (isOpen) setIsActuallyOpen(isOpen);
  }, [isOpen]);

  return { isActuallyOpen, setIsActuallyOpen, isOpen, setMenuOpen };
}

export const ModalContext = createContext(
  {} as ReturnType<typeof useModalContextValue>
);

export function useModalContext() {
  const modalContext = useContext(ModalContext);

  if (!modalContext) {
    throw new Error("Cannot use context outside of provider");
  }

  return modalContext;
}
